.btn-packages{
    height: auto;
}
body{
    color:#000!important;
}
.landing{
    h1, h2, h3, h4, h5, h6{
        color:#000;
    }
    p.text-muted{color: #000!important;}
    header#topnav{
        .navigation-menu{
             li{
                 a{
                     color:#000;
                 }
             }
        }
    }

    ul.brands{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        grid-gap: 1rem;
        .brands__item{
            background: #eee;
            img{
                display: block;
                /* Make sure max-width is added */
                max-width: 100%;
            }
        }
    }
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: inherit!important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: inherit!important;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: inherit!important;
}
div.ps__thumb-y{
    width: 10px!important;
    background: #000!important;
}

.packages-title{
    font-weight: bold;
}
.packages-detail{
    font-size: 15px;
    color: #000;
    dd .packages-value{
        color: #222;
    }
}

    .paket-ozellikleri{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        li.list-inline-item{
            color:#000;
            display: flex;
            justify-content: flex-start;
            text-align: left;
            align-items: flex-start;
            margin-bottom: 10px;
            .icon-box{
                margin-right: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .ozellik-text{
                line-height: 1.2;
                span{font-size: 11px;color:#161537;display: block;}
            }
        }
    }
.card-price-wrapper{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: 16px;
    color: #000;
    align-items: center;
    .card-price-title{
        font-weight: normal;
        span{
            font-size: 14px;
            margin: 0 2px;
        }
    }
    .card-price-price{
        color: #161537;
        font-weight: bold;
        font-size: 18px;
    }
}
.sozlesme-checkbox{
    label{
        font-weight: normal;
        color:#222;
    }
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item{
    padding-left: 16px!important;
    .ant-steps-item-tail{
        display: none!important;
    }
}
ul.list-content{
    label{color:#000;}
    li{padding: 15px;border: 2px solid transparent;transition: all 250ms;}
    li.active{padding: 15px;border-color:#e0e7ff;}
}
.bank-item-wrapper{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #fff;
    border: 1px solid #fff;
    transition: all 250ms;
    div{
        margin: 0 10px;
        &:first-child{
            width: 50px;
        }
    }
    .bank-info{
        display: flex;
        flex-direction: column;
        
        &:nth-child(2){
            max-width: 250px;
        }
        strong{
            color:#000;
        }
        span{
            font-weight: normal;
            color: #000;
        }
    }
}
.bank-item-wrapper-active{
    background: #e0f9f0;
    border-color: #4af7c1;
}


.form-group{
    color:#000;
    &.form-group-error{
    .form-control{
            border-color: red;
        }
        span.error-text{
            color: red;
        }
    }
}

.sozlesme-metni-wrapper{
    font-family: 'Source Sans Pro', sans-serif;
    h1,h2,h3,h4,h5,h6,a{
        color:#000;
        font-family: 'Source Sans Pro', sans-serif;
    }
}